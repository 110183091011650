
import { Component, Ref, Watch } from 'vue-property-decorator'
import ViewModel from '@/models/ViewModel'
import Widget from '@/components/Widget/Widget.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import AdunitScheduler from '@/models/AdunitScheduler'
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import rrulePlugin from '@fullcalendar/rrule'
import LiveEvent from '@/models/LiveEvent'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import fields from './fields'

@Component({
  components: {
    Widget,
    IconAction,
    DataTable,
    SearchInput,
    FullCalendar,
  },
})
export default class AdunitSchedulerHome extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  @Ref() readonly searchInput!: SearchInput

  public loading: boolean = false

  public busy: boolean = true

  public ready: boolean = false

  public records: number = 0

  public fields: Array<any> = []

  public query: string[] = []

  public target: AdunitScheduler = new AdunitScheduler()

  public fieldFilters: any = {}

  public modal: any = {
    delete: false,
    toggle: false,
  }

  public query_settings: any = {
    custom_fields: [
      {
        name: 'is:global',
        value: 'is:global',
      },
      {
        name: 'is_not:global',
        value: 'is_not:global',
      },
      {
        name: 'is:active',
        value: 'is:active',
      },
      {
        name: 'is:inactive',
        value: 'is:inactive',
      },
    ],
  }

  public show_filter_helper: boolean = false

  public filter_mode: string = 'exclusive'

  @Watch('filter_mode')
  public onFilterModeChange() {
    this.refresh()
  }

  public calendarOptions(scheduler: AdunitScheduler) {
    return {
      plugins: [dayGridPlugin, timeGridPlugin, listPlugin, rrulePlugin],
      initialView: 'listMonth',
      nowIndicator: false,
      events: scheduler.calendarEvents,
      headerToolbar: {
        start: 'prev,next, title',
        end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
      },
      bootstrapFontAwesome: {
        close: ' la la-times',
        prev: ' la la-angle-left',
        next: ' la la-angle-right',
        prevYear: ' la la-angle-double-left',
        nextYear: ' la la-angle-double-right',
      },
      buttonText: {
        today: 'Today',
        dayGridMonth: 'Month',
        timeGridWeek: 'Week',
        timeGridDay: 'Day',
        listMonth: 'List',
      },
      editable: false,
      selectable: false,
      selectMirror: true,
      dayMaxEvents: true,
      allDaySlot: false,
      weekends: true,
    }
  }

  public get show_fields() {
    return this.fields.filter(f => f.show)
  }

  public createScheduler() {
    this.$router.push('/app/scheduler')
  }

  public edit(scheduler: AdunitScheduler) {
    this.$router.push(`/app/scheduler/${scheduler.id}`)
  }

  public clone(scheduler: AdunitScheduler) {
    this.$router.push(`/app/scheduler/?from=${scheduler.id}`)
  }

  public activate(scheduler: AdunitScheduler) {
    this.toggle(scheduler)
  }

  public deactivate(scheduler: AdunitScheduler) {
    this.toggle(scheduler)
  }

  public toggle(scheduler: AdunitScheduler) {
    this.target = scheduler
    this.modal.toggle = true
  }

  public confirmToggle() {
    if (this.target.status === 'inactive') {
      this.target.activate().then(() => {
        this.dataTable.refresh()
      })
    } else {
      this.target.deactivate().then(() => {
        this.dataTable.refresh()
      })
    }
  }

  public deleteScheduler(scheduler: AdunitScheduler) {
    this.target = scheduler
    this.modal.delete = true
  }

  public confirmDelete() {
    this.target.delete().then(() => {
      this.dataTable.refresh()
    })
  }

  public rows(context: any) {
    this.loading = true
    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)
    this.syncFilters()
    return AdunitScheduler.paginate({
      page_size: context.perPage,
      page: context.currentPage,
      order_by: context.sortBy,
      order: context.sortDesc ? 'desc' : 'asc',
      query: [...context.filter, ...field_filters],
      mode: this.filter_mode,
    }).then(result => {
      this.records = result.records
      this.loading = false
      return result.data
    })
  }

  public refresh() {
    this.dataTable.refresh()
  }

  public resetFilters() {
    this.fieldFilters = {}
    this.query = []
    this.filter_mode = 'exclusive'

    this.refresh()
  }

  public mounted() {
    this.busy = true
    this.loadFilters()
    const q = this.$route.query
    if (typeof q.query === 'string') {
      const options = q.query.split(',')

      if (options) {
        options.forEach(o => {
          this.searchInput.addCustomTag({
            name: o,
            value: o,
          })

          this.query.push(o)
        })
      }
    }
    this.fields = fields

    // Fectch global Scheduler
    const global_scheduler = AdunitScheduler.module.data.find(s => s.is_global)
    if (!global_scheduler) {
      AdunitScheduler.get('global')
    }
    if (LiveEvent.module.data.length === 0) {
      LiveEvent.fetchAllValidEvents().then(r => {
        this.busy = false
      })
    } else {
      this.busy = false
    }
  }

  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'adunit-schedulers',
      data: { query: this.query, fieldFilters: this.fieldFilters, mode: this.filter_mode },
    })
  }

  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('adunit-schedulers').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
        this.filter_mode = filter.mode
      }
      this.ready = true
    })
  }

  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'adunit-schedulers',
      data: null,
    })
  }
}
