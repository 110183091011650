export default {
  automation_options: [
    {
      group: 'Ohers',
      content: [
        {
          value: 'mlb',
          name: 'MLB',
        },
        {
          value: 'nfl',
          name: 'NFL',
        },
        {
          value: 'nhl',
          name: 'NHL',
        },
        {
          value: 'ncaa_football',
          name: 'NCAA Football',
        },
      ],
    },
    {
      group: 'Basketball',
      content: [
        {
          value: 'nba',
          name: 'NBA',
        },
        {
          value: 'wnba',
          name: 'WNBA',
        },
        {
          value: 'ncaa_basketball_men',
          name: "NCAA Basketball Men's",
        },
        {
          value: 'ncaa_basketball_women',
          name: "NCAA Basketball Women's",
        },
      ],
    },
    {
      group: 'Soccer',
      content: [
        { value: 'uefa_euro_championship', name: 'UEFA European Championship' },
        { value: 'uefa_champions_league', name: 'UEFA Champions League' },
        { value: 'uefa_europa_league', name: 'UEFA Europa League' },
        { value: 'uefa_europa_conference_league', name: 'UEFA Europa Conference League' },
        { value: 'uefa_women_champions_league', name: "UEFA Women's Champions League" },
        { value: 'fifa_world_cup_qualifying_uefa', name: 'FIFA World Cup Qualifying - UEFA' },
        {
          value: 'fifa_women_world_cup_qualifying_uefa',
          name: "FIFA Women's World Cup Qualifying - UEFA",
        },
        { value: 'uefa_women_european_championship', name: "UEFA Women's European Championship" },
        {
          value: 'uefa_european_championship_quilifying',
          name: 'UEFA European Championship Qualifying',
        },
        { value: 'uefa_nations_league', name: 'UEFA Nations League' },
        { value: 'conmebol_uefa_cup_of_champions', name: 'CONMEBOL-UEFA Cup of Champions' },
        {
          value: 'conmebol_uefa_women_cup_of_champions',
          name: "CONMEBOL-UEFA Women's Cup of Champions",
        },
        { value: 'uefa_champions_league_qualifying', name: 'UEFA Champions League Qualifying' },
        { value: 'uefa_europa_league_qualifying', name: 'UEFA Europa League Qualifying' },
        {
          value: 'uefa_europa_conference_league_qualifying',
          name: 'UEFA Europa Conference League Qualifying',
        },
        { value: 'conmebol_uefa_club_challenge', name: 'CONMEBOL-UEFA Club Challenge' },
        { value: 'uefa_super_cup', name: 'UEFA Super Cup' },
        { value: 'copa_america', name: 'Copa América' },
        { value: 'spanish_copa_del_rey', name: 'Spanish Copa del Rey' },
        { value: 'spanish_copa_de_la_reina', name: 'Spanish Copa de la Reina' },
        { value: 'mexican_copa_mx', name: 'Mexican Copa MX' },
        { value: 'copa_america_feminina', name: 'Copa América Femenina' },
        {
          value: 'argentine_copa_de_la_liga_profesional',
          name: 'Argentine Copa de la Liga Profesional',
        },
        { value: 'argentine_copa_de_la_superliga', name: 'Argentine Copa de la Superliga' },
        { value: 'copa_do_brasil', name: 'Copa do Brasil' },
        { value: 'copa_do_nordeste', name: 'Copa do Nordeste' },
        { value: 'copa_chile', name: 'Copa Chile' },
        { value: 'international_friendly', name: 'International Friendly' },
        {
          value: 'fifa_world_cup_qualifying_concacaf',
          name: 'FIFA World Cup Qualifying - Concacaf',
        },
        { value: 'fifa_world_cup_qualifying_caf', name: 'FIFA World Cup Qualifying - CAF' },
        { value: 'fifa_world_cup_qualifying_afc', name: 'FIFA World Cup Qualifying - AFC' },
        { value: 'women_internation_friendly', name: "Women's International Friendly" },
        { value: 'fifa_world_cup', name: 'FIFA World Cup' },
        {
          value: 'fifa_wolrd_cup_qualifying_conmebol',
          name: 'FIFA World Cup Qualifying - CONMEBOL',
        },
        { value: 'fifa_wolrd_cup_qualifying_ofc', name: 'FIFA World Cup Qualifying - OFC' },
        {
          value: 'fifa_wolrd_cup_qualifying_afc_playoff',
          name: 'FIFA World Cup Qualifying - AFC/CONMEBOL Playoff',
        },
        {
          value: 'fifa_wolrd_cup_qualifying_ofc_playoff',
          name: 'FIFA World Cup Qualifying - Concacaf/OFC Playoff',
        },
        {
          value: 'fifa_women_world_cup_qualifying_playoff',
          name: "FIFA Women's World Cup Qualifying - Playoff Tournament",
        },
        { value: 'shebelives_cup', name: 'SheBelieves Cup' },
        { value: 'fifa_confederation_cup', name: 'FIFA Confederations Cup' },
        { value: 'men_olympic_tpurnament', name: "Men's Olympic Tournament" },
        { value: 'women_olympic_tournament', name: "Women's Olympic Tournament" },
        { value: 'conmebol_pre_olympic_tournament', name: 'CONMEBOL Pre-Olympic Tournament' },
        { value: 'men_olympic_qualifying_playoff', name: "Men's Olympic Qualifying Playoff" },
        { value: 'concacaf_women_olympic_qualifying', name: "Concacaf Women's Olympic Qualifying" },
        { value: 'intercontinental_cup_india', name: 'Intercontinental Cup (India)' },
        { value: 'leagues_cup', name: 'Leagues Cup' },
        { value: 'english_carabao_cup', name: 'English Carabao Cup' },
        { value: 'saudi_king_cup', name: "Saudi King's Cup" },
        { value: 'scottish_cup', name: 'Scottish Cup' },
        { value: 'concacaf_champions_cup', name: 'Concacaf Champions Cup' },
        { value: 'usl_jägermeister_cup', name: 'USL Jägermeister Cup' },
        { value: 'concacaf_gold_cup', name: 'Concacaf Gold Cup' },
        { value: 'concacaf_gold_cup_qualifying', name: 'Concacaf Gold Cup Qualifying' },
        { value: 'concacaf_w_gold_cup', name: 'Concacaf W Gold Cup' },
        { value: 'concacaf_cup', name: 'Concacaf Cup' },
        { value: 'afc_asian_cup', name: 'AFC Asian Cup' },
        { value: 'africa_cup_of_nations', name: 'Africa Cup of Nations' },
        { value: 'africa_cup_of_nations_qualifying', name: 'Africa Cup of Nations Qualifying' },
        { value: 'wafu_cup_of_nations', name: 'WAFU Cup of Nations' },
        { value: 'international_champions_cup', name: 'International Champions Cup' },
        { value: 'nwsl_challenge_cup', name: 'NWSL Challenge Cup' },
        { value: 'women_international_champions_cup', name: "Women's International Champions Cup" },
        { value: 'spanish_supercopa', name: 'Spanish Supercopa' },
        { value: 'french_trophee_des_champions', name: 'French Trophee des Champions' },
        { value: 'italian_supercoppa', name: 'Italian Supercoppa' },
        { value: 'german_dfl_supercup', name: 'German DFL-Supercup' },
        { value: 'audi_cup', name: 'Audi Cup' },
        { value: 'emirates_cup', name: 'Emirates Cup' },
        { value: 'arnold_clark_cup', name: 'Arnold Clark Cup' },
        { value: 'pinatar_cup', name: 'Pinatar Cup' },
        { value: 'scottish_league_challenge_cup', name: 'Scottish League Challenge Cup' },
        { value: 'dutch_eredivisie_cup', name: 'Dutch Eredivisie Cup' },
        { value: 'dutch_knvb_beker_vrouwen', name: 'Dutch KNVB Beker Vrouwen' },
        { value: 'aff_cup', name: 'AFF Cup' },
        { value: 'chilean_supercopa', name: 'Chilean Supercopa' },
        { value: 'campeones_cup', name: 'Campeones Cup' },
        { value: 'concacaf_champions_cup', name: 'CONCACAF Champions Cup' },
        { value: 'bangabandhu_cup', name: 'Bangabandhu Cup' },
        { value: 'cosafa_cup', name: 'COSAFA Cup' },
        { value: 'caf_confederation_cup', name: 'CAF Confederation Cup' },
        { value: 'south_african_telkom_knockout_cup', name: 'South African Telkom Knockout Cup' },
        { value: 'south_african_nedbank_cup', name: 'South African Nedbank Cup' },
        { value: 'afc_challenge_cup', name: 'AFC Challenge Cup' },
        { value: 'mls', name: 'MLS' },
        { value: 'club_friendly', name: 'Club Friendly' },
        { value: 'usl_championship', name: 'USL Championship' },
        { value: 'usl_league_one', name: 'USL League One' },
      ],
    },
  ],
  overwrite_type_options: [
    {
      value: 'cancel',
      name: 'Cancel',
    },
  ],
  automated_mode_options: [
    {
      value: 'game_duration',
      name: 'Only Active during live games',
    },
    {
      value: 'all_day',
      name: 'Active all day, but only on game days',
    },
  ],
  status_options: [
    {
      value: 'active',
      name: 'Active',
    },
    {
      value: 'inactive',
      name: 'Inactive',
    },
  ],
  type_options: [
    {
      value: 'once',
      name: 'Once',
    },
    {
      value: 'weekly',
      name: 'Weekly',
    },
    {
      value: 'monthly',
      name: 'Monthly',
    },
    {
      value: 'automated',
      name: 'Automated',
    },
  ],

  weekdays_options: [
    {
      value: 'monday',
      name: 'Monday',
    },
    {
      value: 'tuesday',
      name: 'Tuesday',
    },
    {
      value: 'wednesday',
      name: 'Wednesday',
    },
    {
      value: 'thursday',
      name: 'Thursday',
    },
    {
      value: 'friday',
      name: 'Friday',
    },
    {
      value: 'saturday',
      name: 'Saturday',
    },
    {
      value: 'sunday',
      name: 'Sunday',
    },
  ],

  monthdays_options: [
    {
      value: '1',
      name: '1st',
    },
    {
      value: '2',
      name: '2nd',
    },
    {
      value: '3',
      name: '3rd',
    },
    {
      value: '4',
      name: '4th',
    },
    {
      value: '5',
      name: '5th',
    },
    {
      value: '6',
      name: '6th',
    },
    {
      value: '7',
      name: '7th',
    },
    {
      value: '8',
      name: '8th',
    },
    {
      value: '9',
      name: '9th',
    },
    {
      value: '10',
      name: '10th',
    },
    {
      value: '11',
      name: '11th',
    },
    {
      value: '12',
      name: '12th',
    },
    {
      value: '13',
      name: '13th',
    },
    {
      value: '14',
      name: '14th',
    },
    {
      value: '15',
      name: '15th',
    },
    {
      value: '16',
      name: '16th',
    },
    {
      value: '17',
      name: '17th',
    },
    {
      value: '18',
      name: '18th',
    },
    {
      value: '19',
      name: '19th',
    },
    {
      value: '20',
      name: '20th',
    },
    {
      value: '21',
      name: '21st',
    },
    {
      value: '22',
      name: '22nd',
    },
    {
      value: '23',
      name: '23rd',
    },
    {
      value: '24',
      name: '24th',
    },
    {
      value: '25',
      name: '25th',
    },
    {
      value: '26',
      name: '26th',
    },
    {
      value: '27',
      name: '27th',
    },
    {
      value: '28',
      name: '28th',
    },
    {
      value: '29',
      name: '29th',
    },
    {
      value: '30',
      name: '30th',
    },
    {
      value: '31',
      name: '31st',
    },
    {
      value: 'nth-1',
      name: 'Last day of the month',
    },
  ],
}
