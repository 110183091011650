import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import AdunitScheduler from '@/models/AdunitScheduler'

const { user } = getModule(SystemtModule)

export default [
  {
    key: 'name',
    label: 'Name',
    sortable: true,
    show: true,
  },
  {
    key: 'is_global',
    label: 'Global',
    sortable: true,
    show: true,
    tdClass: 'align-middle text-center',
    type: 'badge',
    formatter: (value: boolean) => (value ? 'Yes' : 'No'),
    color: (scheduler: AdunitScheduler) => (scheduler.is_global ? 'info' : 'secondary'),
  },
  {
    key: 'apply_to_children',
    label: 'Include Children',
    sortable: true,
    show: true,
    type: 'badge',
    formatter: (value: boolean, key: String, scheduler: AdunitScheduler) => (scheduler.is_global ? 'N/A' : value ? 'Yes' : 'No'),
    color: (scheduler: AdunitScheduler) => (scheduler.apply_to_children ? 'info' : 'secondary'),
    tdClass: 'align-middle text-center',
  },
  {
    key: 'status',
    label: 'Status',
    sortable: true,
    show: true,
    type: 'badge',
    color: (scheduler: AdunitScheduler) => (scheduler.status === 'active' ? 'success' : 'danger'),
    tdClass: 'align-middle text-center',
  },
  {
    key: 'created_at',
    label: 'Created At',
    sortable: true,
    show: true,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'updated_at',
    label: 'Updated At',
    sortable: true,
    show: false,
    type: 'date',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'activated_at',
    label: 'Last Activation',
    sortable: true,
    show: true,
    type: 'datetime',
    tdClass: 'align-middle text-center',
  },
  {
    key: 'action',
    type: 'action',
    label: 'Action',
    sortable: false,
    show: true,
    tdClass: 'align-middle text-center',
    actions: [
      {
        icon: 'search',
        title: 'Show Details',
        event: 'details',
      },
      {
        icon: 'edit',
        title: 'Edit',
        event: 'edit',
        show: () => user && user.can('scheduler', 'edit'),
      },
      {
        icon: 'copy',
        title: 'Clone',
        event: 'clone',
        show: () => user && user.can('scheduler', 'edit'),
      },
      {
        icon: 'pause',
        title: 'Deactivate',
        event: 'deactivate',
        show: (i: AdunitScheduler) => i.status === 'active' && user && user.can('scheduler', 'edit'),
      },
      {
        icon: 'play',
        title: 'Activate',
        event: 'activate',
        show: (i: AdunitScheduler) => i.status === 'inactive' && user && user.can('scheduler', 'edit'),
      },
      {
        icon: 'trash',
        title: 'Delete',
        event: 'delete',
        show: () => user && user.can('scheduler', 'delete'),
      },
    ],
  },
]
